<template>
	<Meeting></Meeting>
</template>

<script>
import Meeting from "@/components/shared/tools/meetingSummary/Meeting.vue";

export default {
	name: "MeetingDetailPage",

	components: {
		Meeting,
	},

	created() {
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("redirect-back", (payload) => {
				this.$router.push({ name: "MeetingSummary" });
			});
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("redirect-back");
	},
};
</script>
